import { Button, Typography } from "@mui/material";

export default function ContainedButton({
  children,
  name,
  nameLast,
  sx,
  ...props
}) {
  return (
    <Button
      sx={{
        bgcolor: "rgb(79, 60, 201)",
        color: "white",
        height: "36.5px",
        p: "6px 16px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        ":hover": { bgcolor: "rgb(55, 42, 140)" },
        ":disabled": { bgcolor: "#D7D7D7" },
        ...sx,
      }}
      {...props}
    >
      {nameLast && name && <Typography fontSize="0.875rem">{name}</Typography>}
      {children}
      {!nameLast && name && <Typography fontSize="0.875rem">{name}</Typography>}
    </Button>
  );
}
