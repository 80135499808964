import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoom } from "../api/admin";
import { Box, Tab, IconButton } from "@mui/material";
import { FeaturesList } from "../boom/FeaturesList";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab1 from "./tabs/tab1";
import Tab2 from "./tabs/tab2";
import Tab3 from "./tabs/tab3";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function Room() {
  const search = useLocation().search;
  const [data, setData] = useState({});
  const [file, setFile] = useState();
  const [value, setValue] = useState("1");
  const [error, setError] = useState([]);
  const [availability, setAvailability] = useState();
  const navigate = useNavigate();

  let RL = ["title", "cap", "maxCap", "price", "capPrice", "count"];
  useEffect(() => {
    setError((x) => []);
    RL.forEach((x) => {
      if (typeof data[x] !== "boolean" && (!data[x] || data[x] === ""))
        setError((item) => [item, ...x]);
    });
  }, [data]);

  const handleChange = (changedVal, val, i) => {
    setData((x) => {
      let d = { ...x };
      if (!d[val]) d[val] = [];
      d[val].find((x) => {
        return x.value === changedVal;
      })
        ? (d[val] = d[val].filter((x) => x.value !== changedVal))
        : d[val].push(FeaturesList[i].list.find((x) => x.value === changedVal));
      return d;
    });
  };

  const handleQuery = (name) => {
    return search.indexOf(`${name}=`) !== -1
      ? search
          .substring(
            search.indexOf(`${name}=`),
            (search
              .substring(search.indexOf(`${name}=`), search.length)
              .indexOf("&") !== -1
              ? search
                  .substring(search.indexOf(`${name}=`), search.length)
                  .indexOf("&")
              : search.length - search.indexOf(`${name}=`)) +
              search.indexOf(`${name}=`)
          )
          .substring(name.length + 1)
      : undefined;
  };

  const id = handleQuery("id");
  const BoomId = handleQuery("BoomId");

  useEffect(() => {
    !BoomId && !id && navigate(-1);
  }, [BoomId, id]);

  useEffect(() => {
    id &&
      getRoom(id)
        .then((x) => {
          if (x.status === "ok") {
            setData(() => {
              let d = { ...x.data };
              FeaturesList.map((x) => {
                d[x.value].map((item, index) => {
                  d[x.value][index].value =
                    d[x.value][index].value.toUpperCase();
                });
              });
              return d;
            });
            x.data.image && x.data.image !== "" && setFile(x.data.image);
          }
        })
        .catch((err) => console.error(err));
  }, []);

  const changeValue = (name, e) => {
    setData((x) => {
      let d = { ...x };
      let val = e?.target?.value;
      typeof e === "boolean"
        ? (d[name] = e)
        : val === "" || !val
        ? (d[name] = val)
        : (d[name] = isNaN(Number(val)) ? val : Number(val));
      return d;
    });
  };

  return (
    <>
      <Box width="100%">
        <TabContext value={value}>
          <Box
            position="relative"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <IconButton
              onClick={() => navigate(-1)}
              sx={{ position: "absolute", left: "0", zIndex: "1", mt: "4px" }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <TabList
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <Tab label="تعریف" value="1" />
              <Tab
                disabled={Boolean(id ? error[0] : error[0] || !file)}
                label="امکانات"
                value="2"
              />
              {id && <Tab label="گالری" value="3" />}
            </TabList>
          </Box>
          <TabPanel value="1">
            <Tab1
              availability={availability}
              setAvailability={setAvailability}
              error={error}
              setValue={setValue}
              data={data}
              setFile={setFile}
              file={file}
              changeValue={changeValue}
              id={id}
            />
          </TabPanel>
          <TabPanel value="2">
            <Tab2
              BoomId={BoomId}
              RL={RL}
              file={file}
              id={id}
              data={data}
              handleChange={handleChange}
              FeaturesList={FeaturesList}
              setValue={setValue}
              availability={availability}
            />
          </TabPanel>
          <TabPanel value="3">
            <Tab3 id={id} />
          </TabPanel>
        </TabContext>
      </Box>
      <Box display="flex" gap="10px">
        {/* <TextField
          p="10px"
          className="ra-input"
          label="عنوان"
          value={data?.title || ""}
          // InputProps={{
          //   endAdornment: <InputAdornment position="start">نفر</InputAdornment>,
          // }}
          onChange={(e) => changeValue("title", e)}
          fullWidth
        /> */}
      </Box>
    </>
  );
}
