import * as React from "react";
import { AppBar, TitlePortal } from "react-admin";
import { Box, Typography, useMediaQuery } from "@mui/material";
import MyUserMenu from "./UserMenu";

import logo from "./logo.png";

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <AppBar userMenu={<MyUserMenu />} sx={{ bgColor: "white" }} elevation={1}>
      <TitlePortal />
      {isLargeEnough && (
        <Box display="flex" alignItems="center" gap="10px">
          <Box
            bgcolor="#db3700"
            height="40px"
            display="flex"
            p="0 12px 0 5px"
            alignitems="center"
          >
            <Typography fontFamily="Segoesc !important" fontSize="26.5px">
              Business
            </Typography>
          </Box>
          <img src={logo} alt="" height="40px" />
        </Box>
      )}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
