import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { answerToRequest, getActiveRequests } from "../../api/admin";
import MyField from "../../MyField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OutlinedButton from "../../OutlinedButton";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

export default function AnswerRequest({ roomId }) {
  const [req, setReq] = useState();
  const [reqId, setReqId] = useState(0);

  useEffect(() => {
    if (!roomId) return;

    getActiveRequests(roomId)
      .then((x) => x.status === "ok" && setReq(x.data))
      .catch(() => {
        console.log("wtf");
      });
  }, [roomId]);

  console.log(req);
  return (
    <>
      {req && req[0] ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              disabled={!req[reqId + 1]}
              onClick={() => setReqId((x) => req[reqId + 1] && x++)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <Typography>درخواست {reqId + 1}</Typography>
            <IconButton
              disabled={reqId === 0}
              onClick={() => setReqId((x) => reqId !== 0 && x--)}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
          <Grid container rowSpacing={1} mb={1} p="0px 10px">
            <Grid item xs={6} mb="25px">
              <MyField title="نام درخواست کننده">
                {req[reqId].creator.nameFa} {req[reqId].creator.lastNameFa}
              </MyField>
            </Grid>
            <Grid item xs={6} mb="25px">
              <MyField title="تعداد مسافران">
                {req[reqId].adults} بزرگسال , {req[reqId].child} کودک
              </MyField>
            </Grid>
            <Grid item xs={6} mb="25px">
              <MyField title="قیمت کل سفارش">
                {req[reqId].totalAmount} تومان
              </MyField>
            </Grid>
            <Grid item xs={6} mb="25px">
              <MyField title="تاریخ شروع - پایان">
                {req[reqId].prices[0].date} - {req[reqId].prices.length} شب
              </MyField>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <OutlinedButton
              name="تأیید"
              onClick={() => {
                answerToRequest(req[reqId].id, true);
              }}
            >
              <ThumbUpIcon sx={{ color: "#0D6650" }} />
            </OutlinedButton>
            <OutlinedButton
              name="رد"
              onClick={() => {
                answerToRequest(req[reqId].id, false);
              }}
            >
              <ThumbDownIcon sx={{ color: "#DB3700" }} />
            </OutlinedButton>
          </Box>
        </>
      ) : (
        <Typography>پیشنهادی موجود نیست</Typography>
      )}
    </>
  );
}
