import { Box, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import RoomEdit from "./edits/RoomEdit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AnswerRequest from "./edits/AnswerRequest";
import PriceEdit from "./edits/PriceEdit";
import DatePriceEdit from "./edits/DatePriceEdit";
import { useNavigate } from "react-router-dom";

const EditItem = ({ children, onClick, onBackClick }) => {
  return (
    <Box
      sx={
        onClick && {
          ":hover": { bgcolor: "#00000024" },
          cursor: "pointer",
        }
      }
      borderBottom="1px solid #D9D9D9"
      p="15px"
      pl="0"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick ? onClick : () => {}}
    >
      <Box fontSize="14px" fontWeight="Bold" color="#838080">
        {children}
      </Box>
      {onBackClick && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onBackClick();
          }}
        >
          <ArrowBackIosIcon sx={{ pl: "7px" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default function EditList({ onClose, roomId }) {
  const [w, setW] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (w === "خروج") {
      setW();
      onClose();
    } else if (w === "جزئیات و ویرایش") {
      navigate(`../../Room?id=${roomId}`);
    }
  }, [w]);

  const list = [
    "مشاهده پیشنهاد",
    "قیمت گذاری",
    "لیست مهمانان",
    "جزئیات و ویرایش",
    "مدیریت تخفیف ها",
    "فروش",
    "تقویم قیمت اتاق",
    "وضعیت نظافت",
    "حذف اتاق",
    "خروج",
  ];

  return (
    <Box width="100%">
      {!w &&
        list.map((x, i) => (
          <EditItem key={i} onClick={() => setW(x)}>
            {x}
          </EditItem>
        ))}
      {w && (
        <>
          <EditItem onBackClick={() => setW()}>{w}</EditItem>
          <Box px="20px">
            {list[0] === w ? (
              <AnswerRequest roomId={roomId} />
            ) : list[1] === w ? (
              <PriceEdit roomId={roomId} />
            ) : list[2] === w ? (
              <></>
            ) : list[3] === w ? (
              <></>
            ) : // <RoomEdit roomId={roomId} />
            list[4] === w ? (
              <></>
            ) : list[5] === w ? (
              <></>
            ) : list[6] === w ? (
              <DatePriceEdit />
            ) : list[7] === w ? (
              <></>
            ) : list[8] === w ? (
              <></>
            ) : (
              <></>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
