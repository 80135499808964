import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { addToGallery, getGalleries, removeFromGallery } from "../../api/admin";
import GallaryBox from "../GallaryBox";

export default function Tab3({ id }) {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    getGalleries(id).then((x) => x.status === "ok" && setGallery(x.data));
  }, []);

  const addPic = (file) => {
    if (!file) return;
    let fd = new FormData();
    fd.append("file", file);
    addToGallery(id, fd)
      .then((res) => {
        res.status === "ok" && setGallery((x) => [...x, res.url]);
      })
      .catch(console.error);
  };

  const removePic = (i, fileName) => {
    removeFromGallery(id, fileName)
      .then((x) => {
        x.status === "ok" &&
          setGallery((x) => {
            let d = [...x];
            d.splice(i, i + 1);
            return d;
          });
      })
      .catch(console.error);
  };

  return (
    <>
      <Grid container mb={3}>
        <Grid item xs={3}>
          <TextField
            disabled={Boolean(gallery[3])}
            placeholder="انتخاب کنید"
            sx={{
              "& input": {
                opacity: "0 !important",
              },
            }}
            type="file"
            className="ra-input"
            label="عکس"
            onChange={(e) => {
              console.log(e.target.files[0]);
              addPic(e.target.files[0]);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {gallery.map((x, i) => {
          return (
            <Grid item xs={3} key={i}>
              <GallaryBox
                src={x}
                name={x?.substring(x?.lastIndexOf("/") + 1, x.lastIndexOf("."))}
                onDelete={() => {
                  removePic(i, x?.substring(x?.lastIndexOf("/") + 1, x.length));
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
