export const FeaturesList = [
  {
    name: "محدودیت ها",
    value: "limitations",
    list: [
      {value: "SHOE", name: "کفش"},
      {value: "DRUG", name: "مخدرات"}
    ]
  },
  {
    name: "دسترسی پذیری",
    value: "accessibilityFeatures",
    list: [
      { value: "WITH_STAIR", name: "دارای پله" },
      { value: "WITH_OUT_STAIR", name: "بدون پله" },
      { value: "ELEVATOR", name: "آسانسور" },
    ],
  },
  {
    name: "امکانات غذایی",
    value: "foodFacilities",
    list: [
      { value: "FREE_BREAKFAST", name: "صبحانه رایگان" },
      { value: "NON_FREE_BREAKFAST", name: "صبحانه غیر رایگان" },
      { value: "FREE_LAUNCH", name: "ناهار رایگان" },
      { value: "NON_FREE_LAUNCH", name: "ناهار غیر رایگان" },
      { value: "FREE_DINNER", name: "شام رایگان" },
      { value: "NON_FREE_DINNER", name: "شام غیر رایگان" },
    ],
  },
  {
    name: "امکانات جانبی",
    value: "additionalFacilities",
    list: [
      { value: "TERRACE", name: "تراس" },
      { value: "SERVICE_ROOM", name: "سرویس فرنگی" },
      { value: "IRAN_REST_ROOM", name: "سرویس ایرانی" },
      { value: "BATH", name: "حمام" },
      { value: "KITCHEN", name: "آشپزخانه" },
    ],
  },
  {
    name: "وضعیت خواب",
    value: "sleepFeatures",
    list: [
      { value: "SINGLE", name: "تخت تکنفره" },
      { value: "DOUBLE", name: "دو تخت تکنفره" },
      { value: "COUPLE", name: "تخت دونفره" },
      { value: "BED_LINEN", name: "رخت خواب" },
    ],
  },
  {
    name: "وضعیت رفاهی",
    value: "welfares",
    list: [
      { value: "FURNITURE", name: "مبلمان" },
      { value: "CLOSET", name: "کمد لباس" },
      { value: "CABINET", name: "کابینت" },
      { value: "DESK", name: "میز" },
      { value: "TELEVISION", name: "تلوزیون" },
    ],
  },
];
