import { Button, Typography } from "@mui/material";

export default function OutlinedButton({
  children,
  name,
  nameLast,
  sx,
  ...props
}) {
  return (
    <Button
      {...props}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        alignItems: "center",
        bgcolor: "#ffffff",
        borderRadius: "10px",
        height: "32px",
        color: "#4F3CC9",
        border: "1px solid #4F3CC9",
        ":hover": {
          bgcolor: "#F5F5F580",
        },
        ":disabled": { bgcolor: "#D7D7D7" },
        ...sx,
      }}
    >
      {nameLast && name && (
        <Typography color="#4F3CC9" fontSize="14px">
          {name}
        </Typography>
      )}
      {children}
      {!nameLast && name && (
        <Typography color="#4F3CC9" fontSize="14px">
          {name}
        </Typography>
      )}
    </Button>
  );
}
