import * as React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";
import Box from "@mui/material/Box";
import { getToken, removeToken } from "../api/token";
import { signin } from "../api/admin";

const Login = () => {
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("uuid")) {
      login(
        location.search.substring(6, location.search.length),
        location.state ? location.state.nextPathname : "/"
      ).catch((error) => {
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
    } else if (!getToken()) {
      window.location.replace(
        `https://tour.bogenstudio.com/cas/login?redirectUrl=${window.location.href.replace(
          "#",
          "%23"
        )}&callback=https://boom.bogenstudio.com/api/user/setToken`
      );
    } else {
      window.location.replace(
        `https://tour.bogenstudio.com/cas/logout?redirectUrl=${window.location.href.replace(
          "#",
          "%23"
        )}`
      );
      removeToken();
    }
  }, []);

  const handleSubmit = (auth) => {};

  return (
    // <Form onSubmit={handleSubmit} noValidate>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       minHeight: "100vh",
    //       alignItems: "center",
    //       justifyContent: "flex-start",
    //       background: "url(https://source.unsplash.com/featured/1600x900)",
    //       backgroundRepeat: "no-repeat",
    //       backgroundSize: "cover",
    //     }}
    //   >
    //     <Card sx={{ minWidth: 300, marginTop: "6em" }}>
    //       <Box
    //         sx={{
    //           margin: "1em",
    //           display: "flex",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Avatar sx={{ bgcolor: "secondary.main" }}>
    //           <LockIcon />
    //         </Avatar>
    //       </Box>
    //       <Box
    //         sx={{
    //           marginTop: "1em",
    //           display: "flex",
    //           justifyContent: "center",
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         Hint: demo / demo
    //       </Box>
    //       <Box sx={{ padding: "0 1em 1em 1em" }}>
    //         <Box sx={{ marginTop: "1em" }}>
    //           <TextInput
    //             autoFocus
    //             source="username"
    //             label="username"
    //             // disabled={false}
    //             validate={required()}
    //             fullWidth
    //           />
    //         </Box>
    //         <Box sx={{ marginTop: "1em" }}>
    //           <TextInput
    //             source="password"
    //             label="password"
    //             type="password"
    //             // disabled={loading}
    //             validate={required()}
    //             fullWidth
    //           />
    //         </Box>
    //       </Box>
    //       <CardActions sx={{ padding: "0 1em 1em 1em" }}>
    //         <Button
    //           variant="contained"
    //           type="submit"
    //           color="primary"
    //           disabled={loading}
    //           fullWidth
    //         >
    //           {loading && <CircularProgress size={25} thickness={2} />}
    //           {translate("ra.auth.sign_in")}
    //         </Button>
    //       </CardActions>
    //     </Card>
    //   </Box>
    // </Form>
    <></>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;
