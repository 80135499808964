import {
  BooleanField,
  Datagrid,
  List,
  NumberField,
  TextField,
} from "react-admin";
import { useNavigate } from "react-router-dom";

const BoomList = () => {
  const navigate = useNavigate();
  return (
    <List>
      <Datagrid
        rowClick={(id) => {
          navigate(`/Booms/${id}`);
        }}
      >
        <TextField source="createdAt" />
        <NumberField source="rooms" />
        <TextField source="id" />
        <NumberField source="place.c" />
        <BooleanField source="availability" />
      </Datagrid>
    </List>
  );
};

export default BoomList;
