import { delete_, get, post, put } from ".";

export const signin = (uuid) => {
  return get(`user/getToken?uuid=${uuid}`);
};

export const getBooms = () => {
  return get(`boom/manage/list`, true);
};

export const getRooms = (boomId) => {
  return get(`manage/room/list/${boomId}`, true);
};

export const getRoom = (roomId) => {
  return get(`manage/room/get/${roomId}`, true);
};

export const setRoom = (boomId, data) => {
  return post(`manage/room/store/${boomId}`, true, data);
};

export const UpdateRoom = (roomId, data) => {
  return put(`manage/room/update/${roomId}`, true, data);
};

export const toggleVisibility = (roomId) => {
  return put(`manage/room/toggleAccessibility/${roomId}`, true);
};

export const UpdateRoomImage = (roomId, data) => {
  return put(`manage/room/setPic/${roomId}`, true, data);
};

export const getActiveRequests = (roomId) => {
  return get(`manage/reserve/getActiveRequests/${roomId}`, true);
};

export const answerToRequest = (reqId, status) => {
  return put(
    `manage/reserve/answerToRequest/${reqId}/${status ? "accept" : "reject"}`,
    true
  );
};

export const addToGallery = (roomId, data) => {
  return put(`manage/room/addToGallery/${roomId}`, true, data);
};

export const removeFromGallery = (roomId, fileName) => {
  return delete_(`manage/room/removeFromGallery/${roomId}/${fileName}`, true);
};

export const getGalleries = (roomId) => {
  return get(`manage/room/getGalleries/${roomId}`, true);
};
