import { Box, Typography } from "@mui/material";

export default function MyField({ children, title }) {
  return (
    <>
      <Typography fontSize="12px" color="#838080" fontWeight="400">
        {title}
      </Typography>
      <Box display="flex" alignItems="center" height="calc(100% - 18px)">
        {typeof children === "string" ? (
          <Typography fontSize="14px" color="#272727">
            {children}
          </Typography>
        ) : (
          children
        )}
      </Box>
    </>
  );
}
