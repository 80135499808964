import jMoment from "moment-jalaali";
import React, { useState } from "react";

import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

// import DatePicker from "@mui/lab/DatePicker";

export default function MyDatePicker({ onChange, value, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
      <DatePicker
        value={value}
        onChange={onChange}
        {...props}
      />
    </LocalizationProvider>
    // <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
    //   <DatePicker
    //     inputProps={{
    //       className: "ra-input",
    //     }}
    //     InputProps={{
    //       className: "ra-input",
    //     }}
    //     clearable
    //     okLabel="تأیید"
    //     cancelLabel="لغو"
    //     clearLabel="پاک کردن"
    //     labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
    //     value={value}
    //     onChange={onChange}
    //     {...props}
    //   />
    // </MuiPickersUtilsProvider>
  );
}
