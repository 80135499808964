import { Box } from "@mui/material";

const Circle = ({ color }) => {
  return (
    <Box borderRadius="50%" width="12px" height="12px" bgcolor={color}></Box>
  );
};

export default function Days({ data }) {
  return (
    <Box display="flex" gap="4px">
      {data.map((x, i) => {
        if (x) {
          return <Circle key={i} color="#00A800" />;
        } else {
          return <Circle key={i} color="#FF0000" />;
        }
      })}
    </Box>
  );
}
