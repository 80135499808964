import { RecordContextProvider } from "react-admin";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { getRooms } from "../api/admin";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import MyField from "../MyField";
import commafy from "../commafy";
import Days from "../Days";
import EditList from "./EditList";
import TextButton from "../TextButton";
import AddIcon from "@mui/icons-material/Add";

// const BoomShow = () => (
//   // <List
//   //   sort={{ field: "name", order: "ASC" }}
//   //   perPage={20}
//   //   pagination={false}
//   //   component="div"
//   //   actions={false}
//   //   resource={"Booms"}
//   // >
//   <Boom />
//   // </List>
// );

const BoomShow = () => {
  const tablet = useMediaQuery("(max-width:1250px");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  let id = path.substring(7, path.length);
  const [data, setData] = useState();
  const [roomId, setRoomId] = useState();
  useEffect(() => {
    getRooms(id)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          marginLeft: roomId ? (tablet ? "" : "400px") : "",
          transition: "margin 0.3s ease",
          marginTop: "1em",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <TextButton
            name="اضافه کردن"
            nameLast
            onClick={() => {
              navigate(`../../Room?BoomId=${id}`);
            }}
          >
            <AddIcon sx={{ height: "19.5p" }} />
          </TextButton>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ mt: "3px", width: "100% !important" }}
        >
          {data?.data.map((record) => {
            if (record.main)
              return (
                <RecordContextProvider key={record.id} value={record}>
                  <Grid
                    key={record.id}
                    xs={12}
                    sm={8}
                    md={6}
                    lg={4}
                    xl={3}
                    item
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      onClick={() => {
                        setRoomId(record.id);
                      }}
                    >
                      <Card
                        sx={{
                          position: "relative",
                          overflow: "unset",
                        }}
                      >
                        {record.pendingRequests > 0 && (
                          <Box
                            position="absolute"
                            top="35px"
                            left="-4px"
                            bgcolor="#FF0000"
                            height="36px"
                            px="11.5px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            fontSize="15px"
                            fontWeight="Bold"
                            zIndex={10}
                          >
                            {record.pendingRequests} پیشنهاد
                          </Box>
                        )}
                        <CardMedia
                          image={record.image}
                          sx={{ height: 200, borderRadius: "4px 0 0 4px" }}
                        />
                        <Grid container rowSpacing={1} mb={1} p="0px 5px">
                          <Typography
                            width="100%"
                            display="block"
                            mb="29px"
                            mt="12px"
                            color="#272727"
                            fontSize="15px"
                            fontWeight="Bold"
                          >
                            {record.title}
                          </Typography>
                          <Grid item xs={6}>
                            <MyField title="قیمت پایه - تومان">
                              {commafy(record.price)}
                            </MyField>
                          </Grid>
                          <Grid item xs={6}>
                            <MyField title="تعداد مهمان امروز"></MyField>
                          </Grid>
                          <Grid item xs={6}>
                            <MyField title="وضعیت پنج روزه">
                              <Days data={[true, false, false, true, true]} />
                            </MyField>
                          </Grid>
                          <Grid item xs={6}>
                            <MyField title="وضعیت رزرو آنی ">
                              {record.onlineReservation ? "فعال" : "غیر فعال"}
                            </MyField>
                          </Grid>
                          <Grid item xs={6}>
                            <MyField title="ظرفیت پایه">
                              {record.cap} نفر
                            </MyField>
                          </Grid>
                          <Grid item xs={6}>
                            <MyField title="وضعیت نظافت">
                              {record.clean ? "انجام شده" : "انجام نشده"}
                            </MyField>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Grid>
                </RecordContextProvider>
              );
          })}
        </Grid>
      </Box>
      <Drawer
        variant={tablet ? "temporary" : "persistent"}
        open={roomId !== undefined}
        anchor="left"
        onClose={() => setRoomId()}
        sx={{ zIndex: 100 }}
      >
        <Box mt={tablet ? "0px" : "50px"} width="400px" px="0px" gap="10px">
          <EditList onClose={() => setRoomId()} roomId={roomId} />
        </Box>
      </Drawer>
    </>
  );
};

export default BoomShow;
