import Axios from "axios";
import { getToken } from "./token";

export const BaseUrl = "https://boom.bogenstudio.com/api/";

export const apiAgent = Axios.create({ baseURL: BaseUrl });
export const apiAgentWithToken = Axios.create({ baseURL: BaseUrl });

apiAgent.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiAgentWithToken.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
