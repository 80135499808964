import { Box, IconButton, Typography } from "@mui/material";
import { borderRadius } from "@mui/system";

import ClearIcon from "@mui/icons-material/Clear";

export default function GallaryBox({ src, name, onDelete }) {
  return (
    <Box
      sx={{
        bgcolor: "#f0f0f1",
        borderBottom: "1px solid #707070",
        borderRadius: "10px 10px 0 0",
        p: "6px 9px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" gap="8px" alignItems="center">
        <img src={src} alt="" width="64px" height="48px " />
        <Typography color="#838080" fontSize="14px" fontWeight="regular">
          {name}
        </Typography>
      </Box>
      {onDelete && (
        <IconButton onClick={onDelete}>
          <ClearIcon sx={{ color: "#FF0000" }} />
        </IconButton>
      )}
    </Box>
  );
}
