import { Admin, Resource, ListGuesser, CustomRoutes } from "react-admin";
import { dataProvider } from "./component/dataProvider";
import authProvider from "./AuthProvider";
import { Login, Layout } from "./component/layout";
import { Dashboard } from "./component/dashboard";
import customEnglishMessages from "./component/i18n/en";
import customPersianMessages from "./component/i18n/fa";
import polyglotI18nProvider from "ra-i18n-polyglot";
import boom from "./component/boom";
import { Route } from "react-router-dom";
import BoomShow from "./component/boom/BoomShow";
import Room from "./component/room";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === "fa") {
      return customPersianMessages;
    } else {
      return customEnglishMessages;
    }
    // Always fallback on english
  },
  "fa",
  [
    // { locale: "en", name: "English" },
    { locale: "fa", name: "Persian" },
  ]
);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={Layout}
    loginPage={Login}
    dashboard={Dashboard}
    i18nProvider={i18nProvider}
  >
    <Resource name="Booms" {...boom} />
    <Resource name="posts" list={ListGuesser} />
    <Resource name="comments" list={ListGuesser} />
    <CustomRoutes>
      <Route path="Booms/:id" element={<BoomShow />} />
      <Route path="Room" element={<Room />} />
    </CustomRoutes>
  </Admin>
);

export default App;
