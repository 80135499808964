import { apiAgent, apiAgentWithToken } from "./config";
import { getToken } from "./token";

export async function get(path, token, config) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error?.response, error?.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).get(
      path,
      config
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function delete_(path, token, data, params) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).delete(path, {
      params,
      data,
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function post(path, token, data, config) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).post(
      path,
      data,
      config
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function patch(
  path,
  token,
  data,
  headers = {
    Authorization: `Bearer ${getToken()}`,
  },
  params
) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).patch(
      path,
      data,
      token ? { headers, params } : { params }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function put(
  path,
  token,
  data,
  headers = {
    Authorization: `Bearer ${getToken()}`,
  },
  params
) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).put(
      path,
      data,
      token ? { headers, params } : { params }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}
