import { TextField, Box, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { getRoom, UpdateRoom } from "../../api/admin";
import SaveIcon from "@mui/icons-material/Save";
import ContainedButton from "../../ContainedButton";

export default function PriceEdit({ roomId }) {
  const [roomDetail, setRoomDetail] = useState({});

  useEffect(() => {
    if (!roomId) return;

    getRoom(roomId).then((x) => setRoomDetail(x.status === "ok" ? x.data : {}));
  }, [roomId]);

  const changeValue = (name, e) => {
    setRoomDetail((x) => {
      let d = { ...x };
      let val = e?.target?.value;
      typeof e === "boolean"
        ? (d[name] = e)
        : (d[name] = isNaN(Number(val)) ? val : Number(val));
      return d;
    });
  };

  const featureList = [
    "foodFacilities",
    "sleepFeatures",
    "welfares",
    "accessibilityFeatures",
    "additionalFacilities",
    "limitations",
  ];

  const handleSubmit = () => {
    roomDetail.image && delete roomDetail.image;
    roomDetail.boomId && delete roomDetail.boomId;
    roomDetail.id && delete roomDetail.id;
    roomDetail.no >= 0 && delete roomDetail.no;
    typeof roomDetail.main === "boolean" && delete roomDetail.main;
    roomDetail.createdAt && delete roomDetail.createdAt;
    typeof roomDetail.availability === "boolean" &&
      delete roomDetail.availability;
    roomDetail.datePrices && delete roomDetail.datePrices;
    // typeof roomDetail.onlineReservation === "boolean" &&
    //   delete roomDetail.onlineReservation;
    roomDetail.onlineReservation = true;
    featureList.map((f) => {
      roomDetail[f] = roomDetail[f].map((i) => (i.value ? i.value : i));
    });

    UpdateRoom(
      roomId,
      roomDetail.weekendPrice
        ? {
            ...roomDetail,
            price: roomDetail.price,
            capPrice: roomDetail.capPrice,
            weekendPrice: roomDetail.weekendPrice,
          }
        : {
            ...roomDetail,
            capPrice: roomDetail.capPrice,
            price: roomDetail.price,
          }
    );
  };

  return (
    <>
      <Box display="flex" gap="10px">
        <TextField
          label="قیمت پایه"
          className="ra-input"
          value={roomDetail?.price || 0}
          onChange={(e) => changeValue("price", e)}
          fullWidth
          type="number"
        />
        <TextField
          label="قیمت هر نفر"
          className="ra-input"
          value={roomDetail?.capPrice || 0}
          onChange={(e) => changeValue("capPrice", e)}
          fullWidth
          type="number"
        />
      </Box>
      <Box display="flex" gap="10px" alignItems="center">
        <TextField
          label="قیمت آخر هفته"
          className="ra-input"
          value={roomDetail?.weekendPrice || ""}
          onChange={(e, v) => changeValue("weekendPrice", e)}
          fullWidth
          type="number"
        />
        <ContainedButton onClick={handleSubmit} name="ذخیره">
          <SaveIcon color="white" sx={{ m: "0 !important" }} />
        </ContainedButton>
      </Box>
    </>
  );
}
