import * as React from "react";
import { AppBar, Logout, UserMenu, useUserMenu } from "react-admin";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const MyMenuItem = React.forwardRef(
  ({ name, icon, onClick, ...props }, ref) => {
    // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
    const { onClose } = useUserMenu();
    return (
      <MenuItem
        onClick={() => {
          onClose();
          onClick();
        }}
        ref={ref}
        // It's important to pass the props to allow Material UI to manage the keyboard navigation
        {...props}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{name}</ListItemText>
      </MenuItem>
    );
  }
);

export default function MyUserMenu() {
  let items = [
    { name: "مدیریت کسب و کارها", icon: "", onClick: () => {} },
    { name: "صفحه کسب و کار ", icon: "", onClick: () => {} },
    { name: "صفحه پروفایل ", icon: "", onClick: () => {} },
    {
      name: "تنظیمات",
      icon: <SettingsIcon fontSize="small" />,
      onClick: () => {},
    },
  ];

  return (
    <UserMenu>
      {items.map((x, i) => {
        return <MyMenuItem key={i} {...x} />;
      })}
      <Logout />
    </UserMenu>
  );
}
