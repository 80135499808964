import { Box, Checkbox, Grid, TextField, Typography } from "@mui/material";
import ContainedButton from "../../ContainedButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Tab1({
  availability,
  setAvailability,
  setValue,
  setFile,
  file,
  data,
  changeValue,
  id,
  error,
}) {
  let url = file
    ? typeof file === "string"
      ? file
      : window.URL.createObjectURL(file)
    : undefined;

  return (
    <>
      <Box display="flex">
        <Box width="100%">
          <Typography variant="h6" mb="10px">
            {id && "ویرایش"} اتاق {!id && "جدید"}
          </Typography>
          <Grid container rowSpacing={1} mb={1}>
            <Grid item xs={6}>
              <Box mx="10px">
                <TextField
                  p="10px"
                  className="ra-input"
                  label="عنوان *"
                  value={data?.title || ""}
                  // InputProps={{
                  //   endAdornment: <InputAdornment position="start">نفر</InputAdornment>,
                  // }}
                  onChange={(e) => changeValue("title", e)}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mx="10px" position="relative">
                <Box
                  overflow="hidden"
                  position="absolute"
                  bottom="6.5px"
                  right="10px"
                  ml="5px"
                  width="calc(100% - 18px)"
                >
                  <Typography whiteSpace="nowrap" textOverflow="ellipsis">
                    {file?.name}
                  </Typography>
                </Box>
                <TextField
                  placeholder="انتخاب کنید"
                  sx={{
                    "& input": {
                      opacity: 0,
                    },
                  }}
                  type="file"
                  p="10px"
                  className="ra-input"
                  label="عکس *"
                  InputLabelProps={{
                    shrink: Boolean(file),
                  }}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h6" mb="10px" mt="20px">
            ظرفیت
          </Typography>
          <Grid container rowSpacing={1} mb={1}>
            <Grid item xs={6}>
              <Box mx="10px">
                <TextField
                  p="10px"
                  className="ra-input"
                  label="ظرفیت *"
                  value={data?.cap || 0}
                  onChange={(e) => changeValue("cap", e)}
                  fullWidth
                  type="number"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mx="10px">
                <TextField
                  p="10px"
                  label="حداکثر ظرفیت *"
                  className="ra-input"
                  value={data?.maxCap || 0}
                  onChange={(e) => changeValue("maxCap", e)}
                  fullWidth
                  type="number"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={url} alt="" width="375px" height="225px" />
        </Box>
      </Box>
      <Typography variant="h6" mb="10px" mt="20px">
        قیمت گذاری
      </Typography>
      <Grid container rowSpacing={1} mb={1}>
        <Grid item xs={3}>
          <Box mx="10px">
            <TextField
              p="10px"
              label="قیمت پایه *"
              className="ra-input"
              value={data?.price || 0}
              onChange={(e) => changeValue("price", e)}
              fullWidth
              type="number"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mx="10px">
            <TextField
              p="10px"
              label="قیمت هر نفر *"
              className="ra-input"
              value={data?.capPrice || 0}
              onChange={(e) => changeValue("capPrice", e)}
              fullWidth
              type="number"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mx="10px">
            <TextField
              p="10px"
              label="قیمت آخر هفته"
              className="ra-input"
              value={!!data?.weekendPrice ? data?.weekendPrice : ""}
              onChange={(e, v) => changeValue("weekendPrice", e)}
              fullWidth
              type="number"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mx="10px">
            <TextField
              p="10px"
              label="قیمت تعطیلات"
              className="ra-input"
              value={!!data?.vacationPrice ? data?.vacationPrice : ""}
              onChange={(e, v) => changeValue("vacationPrice", e)}
              fullWidth
              type="number"
            />
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" m="30px 0 20px 0">
        <Grid container rowSpacing={1}>
          <Grid item xs={3}>
            <Box mx="10px">
              <TextField
                p="10px"
                label="تعداد *"
                className="ra-input"
                value={!!data?.count ? data?.count : 0}
                onChange={(e, v) => changeValue("count", e)}
                fullWidth
                type="number"
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box mx="10px" height="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <label htmlFor="availability">
                  <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography px="5px">در دسترس بودن</Typography>
                    <Checkbox
                      id="availability"
                      checked={
                        (typeof availability !== "boolean"
                          ? data?.availability
                          : availability) || false
                      }
                      onChange={() =>
                        setAvailability(
                          typeof availability !== "boolean"
                            ? !data.availability
                            : !availability
                        )
                      }
                    />
                  </Box>
                </label>
              </Box>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box
              height="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              ml="20px"
            >
              <ContainedButton
                disabled={Boolean(id ? error[0] : error[0] || !file)}
                onClick={() => {
                  !(id ? error[0] : error[0] || !file) && setValue("2");
                }}
                name="مرحله بعد"
              >
                <ArrowBackIcon color="white" sx={{ m: "0 !important" }} />
              </ContainedButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
