import englishMessages from "ra-language-english";

const customPersianMessages = {
  ...englishMessages,
  ra: {
    ...englishMessages.ra,
    auth: {
      ...englishMessages.ra.auth,
      user_menu: "پروفایل",
      logout: "خروج",
    },
    page: {
      ...englishMessages.ra.page,
      empty: "هیچ %{name} موجود نیست",
      not_found: "پیدا نشد",
      dashboard: "داشبورد",
    },
    message: {
      ...englishMessages.ra.message,
      not_found:
        "یا آدرس اشتباهی تایپ کرده اید یا پیوند بدی را دنبال کرده اید.",
    },
    action: {
      ...englishMessages.ra.action,
      back: "بازگشت",
      export: "خروجی",
    },
    navigation: {
      ...englishMessages.ra.navigation,
      page_rows_per_page: "ردیف در هر صفحه",
    },
  },
  pos: {
    search: "جستجو",
    configuration: "پیکربندی",
    language: "زبان",
    theme: {
      name: "تم",
      light: "روشن",
      dark: "تیره",
    },
    dashboard: {
      monthly_revenue: "درآمد ماهانه",
      month_history: "تاریخچه درآمد 30 روزه",
      new_orders: "سفارشات جدید",
      pending_reviews: "بازبینی های معلق",
      all_reviews: "مشاهده همه نظرات",
      new_customers: "مشتریان جدید",
      all_customers: "تمام مشتریان",
      pending_orders: "سفارشات معلق",
      order: {
        items:
          "توسط %{customer_name}، یک مورد |||| توسط %{customer_name}، %{nb_items} مورد",
      },
      welcome: {
        title: "به نسخه ی نمایشی تجارت الکترونیک react-admin خوش آمدید",
        subtitle:
          "این مدیر یک فروشگاه پوستر خیالی است. می توانید داده ها را کاوش و اصلاح کنید - این اطلاعات محلی برای رایانه شما است و هر بار که بارگیری مجدد می کنید بازنشانی می شود.",
        ra_button: "react-admin site",
        demo_button: "Source for this demo",
      },
    },
    menu: {
      sales: "فروش",
      catalog: "کاتالوگ",
      customers: "مشتریان",
    },
  },
  resources: {
    customers: {
      name: "مشتری |||| مشتریان",
      fields: {
        commands: "سفارشات",
        first_seen: "اولین بازدید",
        groups: "بخش ها",
        last_seen: "آخرین بازدید",
        last_seen_gte: "بازدید شده",
        name: "اسم",
        total_spent: "تمامی خرج ها",
        password: "رمز ورود",
        confirm_password: "تأیید رمز عبور",
        stateAbbr: "وضعیت",
      },
      filters: {
        last_visited: "آخرین بازدید",
        today: "امروز",
        this_week: "این هفته",
        last_week: "هفته آخر",
        this_month: "این ماه",
        last_month: "آخرین ماه",
        earlier: "نزدیکترین",
        has_ordered: "سفارش داده است",
        has_newsletter: "خبرنامه دارد",
        group: "بخش",
      },
      fieldGroups: {
        identity: "هویت",
        address: "آدرس",
        stats: "آمار",
        history: "تاریخچه",
        password: "رمز عبور",
        change_password: "تغییر رمز عبور",
      },
      page: {
        delete: "حذف کردن",
      },
      errors: {
        password_mismatch: "تأیید رمز عبور با رمز عبور یکسان نیست.",
      },
    },
    commands: {
      name: "سفارش |||| سفارشات",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      fields: {
        basket: {
          delivery: "Delivery",
          reference: "Reference",
          quantity: "Quantity",
          sum: "Sum",
          tax_rate: "Tax Rate",
          taxes: "Tax",
          total: "Total",
          unit_price: "Unit Price",
        },
        address: "Address",
        customer_id: "Customer",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        nb_items: "Nb Items",
        total_gte: "Min amount",
        status: "Status",
        returned: "Returned",
      },
      section: {
        order: "Order",
        customer: "Customer",
        shipping_address: "Shipping Address",
        items: "Items",
        total: "Totals",
      },
    },
    invoices: {
      name: "Invoice |||| Invoices",
      fields: {
        date: "Invoice date",
        customer_id: "Customer",
        command_id: "Order",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        total_gte: "Min amount",
        address: "Address",
      },
    },
    products: {
      name: "Poster |||| Posters",
      fields: {
        category_id: "Category",
        height_gte: "Min height",
        height_lte: "Max height",
        height: "Height",
        image: "Image",
        price: "Price",
        reference: "Reference",
        sales: "Sales",
        stock_lte: "Low Stock",
        stock: "Stock",
        thumbnail: "Thumbnail",
        width_gte: "Min width",
        width_lte: "Max width",
        width: "Width",
      },
      tabs: {
        image: "Image",
        details: "Details",
        description: "Description",
        reviews: "Reviews",
      },
      filters: {
        categories: "Categories",
        stock: "Stock",
        no_stock: "Out of stock",
        low_stock: "1 - 9 items",
        average_stock: "10 - 49 items",
        enough_stock: "50 items & more",
        sales: "Sales",
        best_sellers: "Best sellers",
        average_sellers: "Average",
        low_sellers: "Low",
        never_sold: "Never sold",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fields: {
        products: "Products",
      },
    },
    reviews: {
      name: "Review |||| Reviews",
      amount: "1 review |||| %{smart_count} reviews",
      relative_to_poster: "Review on poster",
      detail: "Review detail",
      fields: {
        customer_id: "Customer",
        command_id: "Order",
        product_id: "Product",
        date_gte: "Posted since",
        date_lte: "Posted before",
        date: "Date",
        comment: "Comment",
        rating: "Rating",
      },
      action: {
        accept: "Accept",
        reject: "Reject",
      },
      notification: {
        approved_success: "Review approved",
        approved_error: "Error: Review not approved",
        rejected_success: "Review rejected",
        rejected_error: "Error: Review not rejected",
      },
    },
    Booms: {
      name: "بوم گردی |||| بوم گردی",
    },
    segments: {
      name: "بخش |||| بخش",
      fields: {
        customers: "فروشندگان",
        name: "اسم",
      },
      data: {
        compulsive: "Compulsive",
        collector: "Collector",
        ordered_once: "Ordered once",
        regular: "Regular",
        returns: "Returns",
        reviewer: "Reviewer",
      },
    },
  },
};

export default customPersianMessages;
