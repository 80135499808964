import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ContainedButton from "../../ContainedButton";
import {
  setRoom,
  toggleVisibility,
  UpdateRoom,
  UpdateRoomImage,
} from "../../api/admin";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";

export default function Tab2({
  RL,
  data,
  handleChange,
  FeaturesList,
  id,
  file,
  setValue,
  BoomId,
  availability,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = () => {
    let SL = [
      "title",
      "cap",
      "maxCap",
      "price",
      "capPrice",
      "weekendPrice",
      "vacationPrice",
      "count",
    ];
    let error = false;
    RL.map((x) => {
      if (!data[x] || data[x] === "") error = true;
    });
    if (!error) {
      let SD = {};
      SL.map((x) => {
        if (data[x] !== null && data[x] !== undefined) {
          SD[x] = data[x];
        }
      });
      if (typeof availability === "boolean") {
        SD.availability = availability;
      }
      [...FeaturesList.map((x) => x.value)].map((x) => {
        if (data[x] !== null && data[x] !== undefined) {
          SD[x] = data[x].map((x) => x.value);
        }
      });
      let fd = new FormData();
      file && fd.append("file", file);
      if (id) {
        UpdateRoom(id, SD);
        typeof availability === "boolean" &&
          availability !== data.availability &&
          toggleVisibility(id);
        file && file !== data.image && UpdateRoomImage(id, SD);
      } else {
        fd.append("data", JSON.stringify(SD));
        setRoom(BoomId, fd).then((x) => {
          x.status === "ok" &&
            window.location.href.replace(location.search, `?id=${x.ids[0]}`);
        });
      }
    }
  };

  return (
    <>
      <Grid container rowSpacing={1.6} mb={1}>
        {FeaturesList.map((item, i) => {
          return (
            <Grid key={i} item xs={6}>
              <Box mx="10px">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="label">{item.name}</InputLabel>
                  <Select
                    sx={{ minHeight: "110px !important" }}
                    // sx={{ width: "100%" }}
                    label={item.menu}
                    labelId="label"
                    multiple
                    value={data[item.value] ? data[item.value] : []}
                    onChange={(e) =>
                      handleChange(
                        e.target.value[e.target.value.length - 1],
                        item.value,
                        i
                      )
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((x, i) => (
                          <Chip
                            sx={{
                              zIndex: 1,
                              "& svg": { m: "0 -6px 0 5px !important" },
                              ":hover": {
                                bgcolor: "#00000023",
                                color: "#000000",
                              },
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              handleChange(x.value, item.value, i);
                            }}
                            key={x.value}
                            label={x.name}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {item.list.map((x) => {
                      let select = data[item.value]?.find((i) => {
                        return i.value === x.value;
                      });
                      return (
                        <MenuItem
                          key={x.value}
                          value={x.value}
                          sx={{
                            fontWeight: select ? "Bold" : "",
                            bgcolor: select ? "#00000023" : "",
                            ":hover": {
                              bgcolor: select ? "#00000023" : "#00000008",
                            },
                          }}
                        >
                          {x.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        m="20px"
        gap="15px"
        mt="30px"
      >
        <ContainedButton
          nameLast
          onClick={() => {
            setValue("1");
          }}
          name="مرحله قبل"
        >
          <ArrowForwardIcon color="white" sx={{ m: "0 !important" }} />
        </ContainedButton>
        <ContainedButton onClick={handleSubmit} name="ذخیره">
          <SaveIcon color="white" sx={{ m: "0 !important" }} />
        </ContainedButton>
      </Box>
    </>
  );
}
