import { getBooms, getList, getRooms } from "./api/admin";

export const dataProvider = {
  getList: async (resource, params) => {
    console.log(resource, params);
    const res = await getBooms();
    return Promise.resolve({
      data: res.data,
      total: res.total || res.data.length || 0,
    });
  },
  getOne: async (resource, params) => {
    console.log(resource, params);
    const res = await getRooms(params.id);
    console.log(res);
    // {
    //   data: res.data,
    //   total: res.total || res.data.length || 0,
    // }
    return Promise.resolve({ ...res, data: { list: res.data, id: params.id } });
  },
  create: async (resource, data) => {
    return fetch(`/api/${resource}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  },

  update: async (resource, id, data) => {
    return fetch(`/api/${resource}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  },

  delete: async (resource, id) => {
    return fetch(`/api/${resource}/${id}`, {
      method: "DELETE",
    }).then((response) => response.json());
  },

  deleteMany: async (resource, params) => {
    return fetch(`/api/${resource}/${params.id}`, {
      method: "DELETE",
    }).then((response) => response.json());
  },
};
